import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DefaultLayout from "components/Layout/DefaultLayout";
import Login from "components/Login";
import Note from "components/Note";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Navigate to="notes" />} />
          <Route path="notes" element={<Note />} />
          <Route
            path="other"
            element={<div style={{ margin: "3em" }}>建设中</div>}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
