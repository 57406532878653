import React, { ReactNode } from "react";
import {
  Form,
  Field,
  FormProps,
  FieldInputProps,
  FieldMetaState,
} from "react-final-form";
import { FaUser, FaLock } from "react-icons/fa";
// import Logo from "../Logo";
import { validateUserName, validatePassword } from "./validators";
import styles from "./index.module.scss";

const ShowError = ({ error }: { error?: string }) =>
  error ? (
    // 另一种错误提示风格
    // <div className="text-danger form-text small pl-3">{error}</div>
    <div className={styles.error}>{error}</div>
  ) : null;

interface RenderInputProps extends React.HtmlHTMLAttributes<HTMLInputElement> {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  label?: string;
  icon: ReactNode;
  // extraAttributes?: Record<string, string>;
}

const renderInput = ({
  input,
  meta,
  label,
  icon,
  ...rest
}: RenderInputProps) => (
  <div className={styles["form-group"]}>
    <div className={styles["input-group"]}>
      <div className={styles.prepend}>{icon}</div>
      <input
        className={styles["form-control"]}
        {...rest}
        {...input}
        placeholder={label}
      />
    </div>
    {meta.touched && <ShowError error={meta.error} />}
  </div>
);

interface LoginFormProps extends FormProps<LoginFormValues> {
  isFetching: boolean;
  // authError?: { error?: string };
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  isFetching,
  // authError,
  ...restProps
}) => (
  <Form<LoginFormValues> onSubmit={onSubmit} {...restProps}>
    {({ handleSubmit, submitError, pristine }) => {
      return (
        <div className={styles.container}>
          {/* <h4 className="text-center font-weight-bold text-info">
            <Logo className="logo" style={{ margin: "0.25rem" }} />
          </h4> */}
          <h4 className={styles.title}>瀚学官网-管理后台</h4>
          <form className={styles.box} onSubmit={handleSubmit}>
            <Field
              name="userName"
              type="text"
              label="用户名"
              icon={<FaUser />}
              component={renderInput}
              validate={validateUserName}
              // 请参考：Password Form Styles that Chromium Understands
              // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
              autoComplete="username"
            />
            <Field
              name="password"
              type="password"
              label="密码"
              icon={<FaLock />}
              component={renderInput}
              validate={validatePassword}
              autoComplete="current-password"

              // extraAttributes={{ autoComplete: "current-password" }}
            />
            <div className={styles["form-group"]}>
              <button
                className={styles.button}
                type="submit"
                disabled={isFetching || pristine}
              >
                登录
              </button>
              <ShowError
                error={
                  submitError //|| (authError && authError.error)
                }
              />
            </div>
          </form>
        </div>
      );
    }}
  </Form>
);

export default LoginForm;
