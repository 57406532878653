import { NavLink } from "react-router-dom";
import { useLogout, useUserName } from "states/auth/hooks";
import styles from "./index.module.scss";

const Navbar = () => {
  const user = useUserName();
  const logout = useLogout();

  const className = ({ isActive }: { isActive: boolean }) =>
    isActive ? styles["active-link"] : styles.link;

  return (
    <header className={styles.header}>
      <div>
        <img className={styles.logo} src="/logo192.png" alt="logo" />
      </div>
      <nav className={styles.nav}>
        <NavLink className={className} to="/notes">
          留言
        </NavLink>
        <NavLink className={className} to="/other">
          建设中。。。
        </NavLink>
      </nav>
      <div className={styles.member}>
        <span>欢迎您, {user}</span>
        <button className={styles.logout} onClick={logout}>
          退出
        </button>
      </div>
    </header>
  );
};

export default Navbar;
